import {inject} from 'vue';
import {useQuery} from '@tanstack/vue-query';

export const useAbsenceKindMandatorySettingsQuery = () => {
    const {$api} = inject('jet');

    return useQuery({
        queryKey: ['getAbsenceKindMandatorySettings'],
        queryFn: () => {
            return $api.absenceRequestKindSettings
                .getMandatorySettings()
                .then(response => response.data)
                .catch($api.end);
        },
        placeholderData: {},
    });
};
