const ABSENCE_REQUEST_KINDS_CODENAMES = {
    HOLIDAY: 'HOLIDAY',
    INJURY: 'INJURY',
    GENERIC: 'GENERIC',
    HOURLY_LEAVE: 'HOURLY_LEAVE',
    DONOR: 'DONOR',
    DISABILITY_SELF: 'DISABILITY_SELF',
    DISABILITY_CHILD: 'DISABILITY_CHILD',
    DISABILITY_FAMILY: 'DISABILITY_FAMILY',
    DISABILITY_MATERNAL: 'DISABILITY_MATERNAL',
    STUDY: 'STUDY',
    MARRIAGE: 'MARRIAGE',
    PARENTAL_MATERNAL_MANDATORY: 'PARENTAL_MATERNAL_MANDATORY',
    PARENTAL_MATERNAL_OPTIONAL: 'PARENTAL_MATERNAL_OPTIONAL',
    PARENTAL_PATERNAL: 'PARENTAL_PATERNAL',
    PARENTAL_LEAVE: 'PARENTAL_LEAVE',
    FEEDING: 'FEEDING',
    SICKNESS_CHILD_INFANT: 'SICKNESS_CHILD_INFANT',
    SICKNESS_CHILD_CHILD: 'SICKNESS_CHILD_CHILD',
    MOURNING: 'MOURNING',
    REMOTE: 'REMOTE',
    OVERTIME: 'OVERTIME',
    TRIP_REGION: 'TRIP_REGION',
    TRIP_ITALY: 'TRIP_ITALY',
    TRIP_UE: 'TRIP_UE',
    TRIP_EXTRA_UE: 'TRIP_EXTRA_UE',
    SICKNESS: 'SICKNESS',
    GENERIC_NON_EMPLOYEE: 'GENERIC_NON_EMPLOYEE',
    HIDDEN: 'HIDDEN',
    // This is not a real codename for absences but is used as placeholder to represent custom absences as whole
    CUSTOM_REQUEST: 'CUSTOM_REQUEST',
};

const ABSENCE_REQUEST_CODENAME_ICON_NAME = {
    // Permessi e congedi
    [ABSENCE_REQUEST_KINDS_CODENAMES.HOURLY_LEAVE]: 'clock-three',
    [ABSENCE_REQUEST_KINDS_CODENAMES.DONOR]: 'clock-three',
    [ABSENCE_REQUEST_KINDS_CODENAMES.DISABILITY_SELF]: 'clock-three',
    [ABSENCE_REQUEST_KINDS_CODENAMES.DISABILITY_CHILD]: 'clock-three',
    [ABSENCE_REQUEST_KINDS_CODENAMES.DISABILITY_FAMILY]: 'clock-three',
    [ABSENCE_REQUEST_KINDS_CODENAMES.DISABILITY_MATERNAL]: 'clock-three',
    [ABSENCE_REQUEST_KINDS_CODENAMES.STUDY]: 'clock-three',
    [ABSENCE_REQUEST_KINDS_CODENAMES.MARRIAGE]: 'clock-three',
    [ABSENCE_REQUEST_KINDS_CODENAMES.PARENTAL_MATERNAL_MANDATORY]: 'clock-three',
    [ABSENCE_REQUEST_KINDS_CODENAMES.PARENTAL_MATERNAL_OPTIONAL]: 'clock-three',
    [ABSENCE_REQUEST_KINDS_CODENAMES.PARENTAL_PATERNAL]: 'clock-three',
    [ABSENCE_REQUEST_KINDS_CODENAMES.PARENTAL_LEAVE]: 'clock-three',
    [ABSENCE_REQUEST_KINDS_CODENAMES.FEEDING]: 'clock-three',
    [ABSENCE_REQUEST_KINDS_CODENAMES.SICKNESS_CHILD_INFANT]: 'clock-three',
    [ABSENCE_REQUEST_KINDS_CODENAMES.SICKNESS_CHILD_CHILD]: 'clock-three',
    [ABSENCE_REQUEST_KINDS_CODENAMES.MOURNING]: 'clock-three',
    // Ferie
    [ABSENCE_REQUEST_KINDS_CODENAMES.HOLIDAY]: 'island-tropical',
    // Straordinari
    [ABSENCE_REQUEST_KINDS_CODENAMES.OVERTIME]: 'briefcase',
    // Malattie
    [ABSENCE_REQUEST_KINDS_CODENAMES.SICKNESS]: 'stethoscope',
    // Infortuni
    [ABSENCE_REQUEST_KINDS_CODENAMES.INJURY]: 'briefcase-medical',
    // Altro
    [ABSENCE_REQUEST_KINDS_CODENAMES.GENERIC]: 'comment-dots',
    // Smartworking
    [ABSENCE_REQUEST_KINDS_CODENAMES.REMOTE]: 'house',
    // Assenze generiche
    [ABSENCE_REQUEST_KINDS_CODENAMES.HIDDEN]: 'arrow-right-from-arc',
    // Trasferte
    [ABSENCE_REQUEST_KINDS_CODENAMES.TRIP_EXTRA_UE]: 'plane',
    [ABSENCE_REQUEST_KINDS_CODENAMES.TRIP_REGION]: 'plane',
    [ABSENCE_REQUEST_KINDS_CODENAMES.TRIP_ITALY]: 'plane',
    [ABSENCE_REQUEST_KINDS_CODENAMES.TRIP_UE]: 'plane',
    [ABSENCE_REQUEST_KINDS_CODENAMES.GENERIC_NON_EMPLOYEE]: 'arrow-right-from-arc',
};

const CODENAME_LABEL_MAPPING = {
    [ABSENCE_REQUEST_KINDS_CODENAMES.HOLIDAY]: 'Ferie',
    [ABSENCE_REQUEST_KINDS_CODENAMES.INJURY]: 'Infortunio',
    [ABSENCE_REQUEST_KINDS_CODENAMES.GENERIC]: 'Altro',
    [ABSENCE_REQUEST_KINDS_CODENAMES.HOURLY_LEAVE]: 'ROL e Ex festività',
    [ABSENCE_REQUEST_KINDS_CODENAMES.DONOR]: 'Donazione sangue',
    [ABSENCE_REQUEST_KINDS_CODENAMES.DISABILITY_SELF]: 'Lavoratore con disabilità',
    [ABSENCE_REQUEST_KINDS_CODENAMES.DISABILITY_CHILD]: 'Assistenza figli con disabilità',
    [ABSENCE_REQUEST_KINDS_CODENAMES.DISABILITY_FAMILY]: 'Assistenza familiari con disabilità',
    [ABSENCE_REQUEST_KINDS_CODENAMES.DISABILITY_MATERNAL]: 'Prolungamento maternità figli con disabilità',
    [ABSENCE_REQUEST_KINDS_CODENAMES.STUDY]: 'Studio e esami',
    [ABSENCE_REQUEST_KINDS_CODENAMES.MARRIAGE]: 'Congedo matrimoniale',
    [ABSENCE_REQUEST_KINDS_CODENAMES.PARENTAL_MATERNAL_MANDATORY]: 'Maternità obbligatoria',
    [ABSENCE_REQUEST_KINDS_CODENAMES.PARENTAL_MATERNAL_OPTIONAL]: 'Maternità facoltativa',
    [ABSENCE_REQUEST_KINDS_CODENAMES.PARENTAL_PATERNAL]: 'Paternità',
    [ABSENCE_REQUEST_KINDS_CODENAMES.PARENTAL_LEAVE]: 'Congedo parentale',
    [ABSENCE_REQUEST_KINDS_CODENAMES.FEEDING]: 'Allattamento',
    [ABSENCE_REQUEST_KINDS_CODENAMES.SICKNESS_CHILD_INFANT]: 'fino a 3 anni',
    [ABSENCE_REQUEST_KINDS_CODENAMES.SICKNESS_CHILD_CHILD]: 'da 3 a 8 anni',
    [ABSENCE_REQUEST_KINDS_CODENAMES.MOURNING]: 'Lutto',
    [ABSENCE_REQUEST_KINDS_CODENAMES.REMOTE]: 'Smartworking',
    [ABSENCE_REQUEST_KINDS_CODENAMES.OVERTIME]: 'Straordinario',
    [ABSENCE_REQUEST_KINDS_CODENAMES.TRIP_REGION]: 'Regione',
    [ABSENCE_REQUEST_KINDS_CODENAMES.TRIP_ITALY]: 'Italia',
    [ABSENCE_REQUEST_KINDS_CODENAMES.TRIP_UE]: 'Unione Europea',
    [ABSENCE_REQUEST_KINDS_CODENAMES.TRIP_EXTRA_UE]: 'Extra Unione Europea',
    [ABSENCE_REQUEST_KINDS_CODENAMES.SICKNESS]: 'Malattia',
    [ABSENCE_REQUEST_KINDS_CODENAMES.HIDDEN]: 'Assenza',
    [ABSENCE_REQUEST_KINDS_CODENAMES.CUSTOM_REQUEST]: 'Permessi aziendali',
    [ABSENCE_REQUEST_KINDS_CODENAMES.GENERIC_NON_EMPLOYEE]: 'Assenza (generico)',
};

const TRIP_CODENAMES = [
    ABSENCE_REQUEST_KINDS_CODENAMES.TRIP_REGION,
    ABSENCE_REQUEST_KINDS_CODENAMES.TRIP_ITALY,
    ABSENCE_REQUEST_KINDS_CODENAMES.TRIP_UE,
    ABSENCE_REQUEST_KINDS_CODENAMES.TRIP_EXTRA_UE,
];

const DISABILITY_CODENAMES = [
    ABSENCE_REQUEST_KINDS_CODENAMES.DISABILITY_SELF,
    ABSENCE_REQUEST_KINDS_CODENAMES.DISABILITY_MATERNAL,
    ABSENCE_REQUEST_KINDS_CODENAMES.DISABILITY_CHILD,
    ABSENCE_REQUEST_KINDS_CODENAMES.DISABILITY_FAMILY,
];

const PARENTAL_LEAVE_CODENAMES = [
    ABSENCE_REQUEST_KINDS_CODENAMES.PARENTAL_LEAVE,
    ABSENCE_REQUEST_KINDS_CODENAMES.PARENTAL_MATERNAL_OPTIONAL,
    ABSENCE_REQUEST_KINDS_CODENAMES.PARENTAL_MATERNAL_MANDATORY,
    ABSENCE_REQUEST_KINDS_CODENAMES.PARENTAL_PATERNAL,
    ABSENCE_REQUEST_KINDS_CODENAMES.PARENTAL_PATERNAL,
];

const SICKNESS_FAMILY_CODENAMES = [
    ABSENCE_REQUEST_KINDS_CODENAMES.SICKNESS_CHILD_CHILD,
    ABSENCE_REQUEST_KINDS_CODENAMES.SICKNESS_CHILD_INFANT,
];

const ABSENCE_REQUEST_STATUS_CHOICES = [
    {value: 'PENDING', display_name: 'In approvazione'},
    {value: 'APPROVED', display_name: 'Approvato'},
];

const ABSENCE_REQUEST_STATUS_LABEL = {
    PENDING: 'In approvazione',
    APPROVED: 'Approvata',
    REJECTED: 'Rifiutata',
};

const ABSENCE_REQUEST_STATUS_COLOR = {
    PENDING: 'warning',
    APPROVED: 'success',
    REJECTED: 'danger',
};

const ABSENCE_REQUEST_STATUS = {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
};

const ABSENCE_RESIDUE_UNIT = {
    DAYS: 'DAYS',
    HOURS: 'HOURS',
};

const HOURLY_ABSENCE_REQUEST_START_TIME_TAG_LABELS = {
    break: 'Pausa',
    entry_time: 'Ingresso',
    exit_time: 'Uscita',
};

const ATTACHMENT_OPTIONS = {
    MANDATORY: 'MANDATORY',
    OPTIONAL: 'OPTIONAL',
    NONE: 'NONE',
};

const SELECT_1_OPTIONS = {
    HOLIDAY: 'HOLIDAY',
    LEAVE_GROUP: 'LEAVE_GROUP',
    COMPANY_LEAVE_GROUP: 'COMPANY_LEAVE_GROUP',
    SICKNESS: 'SICKNESS',
    INJURY: 'INJURY',
    GENERIC: 'GENERIC',
    GENERIC_NON_EMPLOYEE: 'GENERIC_NON_EMPLOYEE',
    REMOTE: 'REMOTE',
    OVERTIME: 'OVERTIME',
    TRIP_GROUP: 'TRIP_GROUP',
};

const SELECT_1_ICON_MAP = {
    [SELECT_1_OPTIONS.HOLIDAY]: 'island-tropical',
    [SELECT_1_OPTIONS.LEAVE_GROUP]: 'clock-three',
    [SELECT_1_OPTIONS.COMPANY_LEAVE_GROUP]: 'calendar-clock',
    [SELECT_1_OPTIONS.SICKNESS]: 'stethoscope',
    [SELECT_1_OPTIONS.INJURY]: 'briefcase-medical',
    [SELECT_1_OPTIONS.GENERIC]: 'comment-dots',
    [SELECT_1_OPTIONS.GENERIC_NON_EMPLOYEE]: 'arrow-right-from-arc',
    [SELECT_1_OPTIONS.REMOTE]: 'house',
    [SELECT_1_OPTIONS.OVERTIME]: 'briefcase',
    [SELECT_1_OPTIONS.TRIP_GROUP]: 'plane',
};

const SELECT_2_OPTIONS = {
    HOURLY_LEAVE: 'HOURLY_LEAVE',
    DONOR: 'DONOR',
    DISABILITY_GROUP: 'DISABILITY_GROUP',
    STUDY: 'STUDY',
    MARRIAGE: 'MARRIAGE',
    PARENTAL_GROUP: 'PARENTAL_GROUP',
    PARENTAL_PATERNAL: 'PARENTAL_PATERNAL',
    SICKNESS_CHILD_GROUP: 'SICKNESS_CHILD_GROUP',
    MOURNING: 'MOURNING',
    TRIP_REGION: 'TRIP_REGION',
    TRIP_ITALY: 'TRIP_ITALY',
    TRIP_UE: 'TRIP_UE',
    TRIP_EXTRA_UE: 'TRIP_EXTRA_UE',
    PARENTAL_LEAVE: 'PARENTAL_LEAVE',
    FEEDING: 'FEEDING',
};

const SELECT_3_OPTIONS = {
    DISABILITY_SELF: 'DISABILITY_SELF',
    DISABILITY_CHILD: 'DISABILITY_CHILD',
    DISABILITY_FAMILY: 'DISABILITY_FAMILY',
    DISABILITY_MATERNAL: 'DISABILITY_MATERNAL',
    PARENTAL_MATERNAL_MANDATORY: 'PARENTAL_MATERNAL_MANDATORY',
    PARENTAL_MATERNAL_OPTIONAL: 'PARENTAL_MATERNAL_OPTIONAL',
    SICKNESS_CHILD_INFANT: 'SICKNESS_CHILD_INFANT',
    SICKNESS_CHILD_CHILD: 'SICKNESS_CHILD_CHILD',
};

const PRESENCE_SELECT_GROUP = [SELECT_1_OPTIONS.REMOTE, SELECT_1_OPTIONS.OVERTIME, SELECT_1_OPTIONS.TRIP_GROUP];

const ABSENCE_SELECT_GROUP = [
    SELECT_1_OPTIONS.HOLIDAY,
    SELECT_1_OPTIONS.LEAVE_GROUP,
    SELECT_1_OPTIONS.COMPANY_LEAVE_GROUP,
    SELECT_1_OPTIONS.SICKNESS,
    SELECT_1_OPTIONS.INJURY,
    SELECT_1_OPTIONS.GENERIC,
    SELECT_1_OPTIONS.GENERIC_NON_EMPLOYEE,
];

/* prettier-ignore */
const ABSENCE_REQUEST_SELECT_MAPPING_TREE = {
    [[SELECT_1_OPTIONS.HOLIDAY, '', '']]: ABSENCE_REQUEST_KINDS_CODENAMES.HOLIDAY,
    [[SELECT_1_OPTIONS.SICKNESS, '', '']]: ABSENCE_REQUEST_KINDS_CODENAMES.SICKNESS,
    [[SELECT_1_OPTIONS.INJURY, '', '']]: ABSENCE_REQUEST_KINDS_CODENAMES.INJURY,
    [[SELECT_1_OPTIONS.GENERIC, '', '']]: ABSENCE_REQUEST_KINDS_CODENAMES.GENERIC,
    [[SELECT_1_OPTIONS.GENERIC_NON_EMPLOYEE, '', '']]: ABSENCE_REQUEST_KINDS_CODENAMES.GENERIC_NON_EMPLOYEE,
    [[SELECT_1_OPTIONS.LEAVE_GROUP, SELECT_2_OPTIONS.HOURLY_LEAVE, '']]: ABSENCE_REQUEST_KINDS_CODENAMES.HOURLY_LEAVE,
    [[SELECT_1_OPTIONS.LEAVE_GROUP, SELECT_2_OPTIONS.DONOR, '']]: ABSENCE_REQUEST_KINDS_CODENAMES.DONOR,
    [[SELECT_1_OPTIONS.LEAVE_GROUP, SELECT_2_OPTIONS.DISABILITY_GROUP, SELECT_3_OPTIONS.DISABILITY_SELF]]: ABSENCE_REQUEST_KINDS_CODENAMES.DISABILITY_SELF,
    [[SELECT_1_OPTIONS.LEAVE_GROUP, SELECT_2_OPTIONS.DISABILITY_GROUP, SELECT_3_OPTIONS.DISABILITY_CHILD]]: ABSENCE_REQUEST_KINDS_CODENAMES.DISABILITY_CHILD,
    [[SELECT_1_OPTIONS.LEAVE_GROUP, SELECT_2_OPTIONS.DISABILITY_GROUP, SELECT_3_OPTIONS.DISABILITY_FAMILY]]: ABSENCE_REQUEST_KINDS_CODENAMES.DISABILITY_FAMILY,
    [[SELECT_1_OPTIONS.LEAVE_GROUP, SELECT_2_OPTIONS.DISABILITY_GROUP, SELECT_3_OPTIONS.DISABILITY_MATERNAL]]: ABSENCE_REQUEST_KINDS_CODENAMES.DISABILITY_MATERNAL,
    [[SELECT_1_OPTIONS.LEAVE_GROUP, SELECT_2_OPTIONS.STUDY, '']]: ABSENCE_REQUEST_KINDS_CODENAMES.STUDY,
    [[SELECT_1_OPTIONS.LEAVE_GROUP, SELECT_2_OPTIONS.MARRIAGE, '']]: ABSENCE_REQUEST_KINDS_CODENAMES.MARRIAGE,
    [[SELECT_1_OPTIONS.LEAVE_GROUP, SELECT_2_OPTIONS.PARENTAL_GROUP, SELECT_3_OPTIONS.PARENTAL_MATERNAL_MANDATORY]]: ABSENCE_REQUEST_KINDS_CODENAMES.PARENTAL_MATERNAL_MANDATORY,
    [[SELECT_1_OPTIONS.LEAVE_GROUP, SELECT_2_OPTIONS.PARENTAL_GROUP, SELECT_3_OPTIONS.PARENTAL_MATERNAL_OPTIONAL]]: ABSENCE_REQUEST_KINDS_CODENAMES.PARENTAL_MATERNAL_OPTIONAL,
    [[SELECT_1_OPTIONS.LEAVE_GROUP, SELECT_2_OPTIONS.PARENTAL_PATERNAL, '']]: ABSENCE_REQUEST_KINDS_CODENAMES.PARENTAL_PATERNAL,
    [[SELECT_1_OPTIONS.LEAVE_GROUP, SELECT_2_OPTIONS.PARENTAL_LEAVE, '']]: ABSENCE_REQUEST_KINDS_CODENAMES.PARENTAL_LEAVE,
    [[SELECT_1_OPTIONS.LEAVE_GROUP, SELECT_2_OPTIONS.FEEDING, '']]: ABSENCE_REQUEST_KINDS_CODENAMES.FEEDING,
    [[SELECT_1_OPTIONS.LEAVE_GROUP, SELECT_2_OPTIONS.SICKNESS_CHILD_GROUP, SELECT_3_OPTIONS.SICKNESS_CHILD_INFANT]]: ABSENCE_REQUEST_KINDS_CODENAMES.SICKNESS_CHILD_INFANT,
    [[SELECT_1_OPTIONS.LEAVE_GROUP, SELECT_2_OPTIONS.SICKNESS_CHILD_GROUP, SELECT_3_OPTIONS.SICKNESS_CHILD_CHILD]]: ABSENCE_REQUEST_KINDS_CODENAMES.SICKNESS_CHILD_CHILD,
    [[SELECT_1_OPTIONS.LEAVE_GROUP, SELECT_2_OPTIONS.MOURNING, '']]: ABSENCE_REQUEST_KINDS_CODENAMES.MOURNING,
    [[SELECT_1_OPTIONS.REMOTE, '', '']]: ABSENCE_REQUEST_KINDS_CODENAMES.REMOTE,
    [[SELECT_1_OPTIONS.OVERTIME, '', '']]: ABSENCE_REQUEST_KINDS_CODENAMES.OVERTIME,
    [[SELECT_1_OPTIONS.TRIP_GROUP, SELECT_2_OPTIONS.TRIP_REGION, '']]: ABSENCE_REQUEST_KINDS_CODENAMES.TRIP_REGION,
    [[SELECT_1_OPTIONS.TRIP_GROUP, SELECT_2_OPTIONS.TRIP_ITALY, '']]: ABSENCE_REQUEST_KINDS_CODENAMES.TRIP_ITALY,
    [[SELECT_1_OPTIONS.TRIP_GROUP, SELECT_2_OPTIONS.TRIP_UE, '']]: ABSENCE_REQUEST_KINDS_CODENAMES.TRIP_UE,
    [[SELECT_1_OPTIONS.TRIP_GROUP, SELECT_2_OPTIONS.TRIP_EXTRA_UE, '']]: ABSENCE_REQUEST_KINDS_CODENAMES.TRIP_EXTRA_UE,
};

function generateUITree(tree) {
    const result = {};

    for (const [key, _] of Object.entries(tree)) {
        // We need to split by , as the key array is stringified by js
        const [select1, select2, select3] = key.split(',');
        if (!result[select1]) {
            result[select1] = {};
        }

        if (select2) {
            if (!result[select1][select2]) {
                result[select1][select2] = {};
            }

            if (select3) {
                result[select1][select2][select3] = {};
            }
        }
    }

    return result;
}

function generateInvertedMap(map) {
    const inverted = {};

    for (const [key, codename] of Object.entries(map)) {
        // we need to split the key because js stringifies the array keys
        const [select1, select2, select3] = key.split(',');
        if (!inverted[codename]) {
            inverted[codename] = {};
        }

        inverted[codename] = [[select1, select2, select3]];
    }

    return inverted;
}

const SELECT_GROUP_LABEL_MAPPING = {
    [SELECT_1_OPTIONS.COMPANY_LEAVE_GROUP]: 'Assenze aziendali',
    [SELECT_1_OPTIONS.TRIP_GROUP]: 'Trasferta',
    [SELECT_1_OPTIONS.LEAVE_GROUP]: 'Permessi e congedi',
    [SELECT_2_OPTIONS.DISABILITY_GROUP]: 'Disabilità (legge 104)',
    [SELECT_2_OPTIONS.PARENTAL_GROUP]: 'Maternità',
    [SELECT_2_OPTIONS.SICKNESS_CHILD_GROUP]: 'Malattia del figlio',
};

/**
 * Maps the selectOption to a corresponding JetSelect Choices
 * @param {string} selectOption
 * @param {boolean} disableChoice
 * @returns {Array<{display_name: string; value: string; kind: string; disabled: boolean;}>}
 */
function mapGroupToChoice(selectOption, disableChoice = false) {
    return {
        display_name: SELECT_GROUP_LABEL_MAPPING[selectOption], // For groups we don't have labels from BE
        value: selectOption,
        disabled: disableChoice,
    };
}

const SELECT_1_FORM_LABEL = {
    [SELECT_1_OPTIONS.LEAVE_GROUP]: 'Tipologia di permesso',
    [SELECT_1_OPTIONS.TRIP_GROUP]: 'Destinazione trasferta',
    [SELECT_1_OPTIONS.COMPANY_LEAVE_GROUP]: 'Tipologia di permesso',
};

const ABSENCE_FORM_TABS = {
    HOURS: 'can_request_hours',
    DAY: 'can_request_day',
    DAYS: 'can_request_days',
};

/**
 * Returns the label for the selectedKind (1st Select)
 * @param {string} selectedKind
 * @returns {string}
 */
function getSelectedSubkindSublabel(selectedKind) {
    return SELECT_1_FORM_LABEL[selectedKind] || '';
}

const SELECT_2_FORM_LABEL = {
    [SELECT_2_OPTIONS.SICKNESS_CHILD_GROUP]: 'Età del figlio',
    [SELECT_2_OPTIONS.PARENTAL_GROUP]: 'Tipologia di maternità',
    [SELECT_2_OPTIONS.DISABILITY_GROUP]: 'Tipologia',
};

/**
 * Returns the label for the selectedSubkind (2nd Select)
 * @param {string} select2Option
 * @returns {string}
 */
function getSelectedSubkindSpecLabel(select2Option) {
    return SELECT_2_FORM_LABEL[select2Option] || '';
}

export {
    ABSENCE_REQUEST_CODENAME_ICON_NAME,
    ABSENCE_REQUEST_STATUS,
    ABSENCE_RESIDUE_UNIT,
    ABSENCE_REQUEST_KINDS_CODENAMES,
    ABSENCE_REQUEST_STATUS_CHOICES,
    ABSENCE_REQUEST_STATUS_LABEL,
    ABSENCE_REQUEST_STATUS_COLOR,
    HOURLY_ABSENCE_REQUEST_START_TIME_TAG_LABELS,
    ATTACHMENT_OPTIONS,
    SELECT_1_OPTIONS,
    SELECT_2_OPTIONS,
    SELECT_3_OPTIONS,
    SELECT_1_ICON_MAP,
    CODENAME_LABEL_MAPPING,
    PRESENCE_SELECT_GROUP,
    ABSENCE_SELECT_GROUP,
    TRIP_CODENAMES,
    DISABILITY_CODENAMES,
    PARENTAL_LEAVE_CODENAMES,
    SICKNESS_FAMILY_CODENAMES,
    SELECT_GROUP_LABEL_MAPPING,
    ABSENCE_REQUEST_SELECT_MAPPING_TREE,
    ABSENCE_FORM_TABS,
    generateUITree,
    generateInvertedMap,
    mapGroupToChoice,
    getSelectedSubkindSublabel,
    getSelectedSubkindSpecLabel,
};
