import {isValidIBAN, isValidBIC} from 'ibantools';
import {parseDatetime} from './date.js';
import {parseTimeIntervalString} from '@jetCommon/helpers/time.js';
import CodiceFiscaleUtils from '@marketto/codice-fiscale-utils';

const requiredFieldRule = {
    required: true,
    message: 'Campo obbligatorio',
    trigger: 'blur',
};

const emailFormat = {
    type: 'email',
    message: 'Inserire un formato email corretto',
    trigger: 'change',
};

const mandatoryEmail = {
    required: true,
    message: 'Inserisci indirizzo email',
    trigger: 'change',
};

const emailRules = [mandatoryEmail, emailFormat];
const optionalEmailRules = [emailFormat];

const ibanFormatValidator = (rule, value, callback) => {
    if (rule.required !== true && value === '') {
        callback();
    }

    if (!isValidIBAN(value.replace(/\s/g, ''))) {
        callback(new Error('Codice IBAN non valido'));
    } else {
        callback();
    }
};

const phoneNumberFormatValidator = (rule, value, callback) => {
    if (value && !/^\+?[0-9]{1,15}$/.test(value)) {
        callback(new Error('Numero di telefono non valido'));
    } else {
        callback();
    }
};

const timeFormatValidator = (rule, value, callback) => {
    if (value && !/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value)) {
        callback(new Error('Orario non valido, formato accettato: HH:MM'));
    } else {
        callback();
    }
};

const phoneNumberFormat = {
    type: 'string',
    trigger: 'change',
    validator: phoneNumberFormatValidator,
};
const mandatoryPhoneNumber = {
    required: true,
    type: 'string',
    message: 'Inserire numero di telefono',
    trigger: 'change',
};

const ibanFormat = {
    type: 'string',
    trigger: 'change',
    validator: ibanFormatValidator,
};

const mandatoryIban = {
    required: true,
    type: 'string',
    message: 'Inserire IBAN',
    trigger: 'change',
};

const ibanRules = [mandatoryIban, ibanFormat];
const optionalIbanRules = [ibanFormat];
const optionalPhoneNumberRules = [phoneNumberFormat];
const phoneNumberRules = [mandatoryPhoneNumber, phoneNumberFormat];

const bicFormatValidator = (rule, value, callback) => {
    if (value && !isValidBIC(value)) {
        callback(new Error('Codice BIC non valido'));
    } else {
        callback();
    }
};

const bicFormat = {
    type: 'string',
    trigger: 'change',
    validator: bicFormatValidator,
};

const mandatoryBic = {
    required: true,
    type: 'string',
    trigger: 'change',
    message: 'Inserisci il codice BIC',
};

const bicRules = [mandatoryBic, bicFormat];
const optionalBicRules = [bicFormat];

const amountValidator = (rule, value, callback) => {
    if (value < 0) {
        callback(new Error("L'importo inserito deve essere maggiore o uguale a 0"));
    } else {
        callback();
    }
};

function percentageValidator(rule, value, callback) {
    const italianFormatValue = value.replace(',', '.');

    if (italianFormatValue === '' || isNaN(italianFormatValue) || italianFormatValue < 0 || italianFormatValue > 100) {
        callback(new Error('Inserire un valore numerico compreso tra 0 e 100'));
    } else {
        callback();
    }
}

function dayOfMonthValidator(rule, value, callback) {
    const intValue = Number.parseInt(value, 10);
    if (isNaN(intValue)) {
        callback(new Error('Il valore inserito deve essere un numero valido'));
    } else if (value < 0 || value > 31) {
        callback(new Error('Il valore inserito deve essere tra 1 e 31'));
    } else {
        callback();
    }
}

const addressFormRules = {
    street: [requiredFieldRule],
    country: [requiredFieldRule],
    province: [requiredFieldRule],
    municipality: [requiredFieldRule],
    postcode: [requiredFieldRule],
};

function addressValidator(rule, value, callback) {
    if (!value) {
        return callback(new Error("L'indirizzo è obbligatorio"));
    }
    const {street, postcode, province, municipality} = value;

    if (!street || street.trim() === '') {
        return callback(new Error('La via è obbligatoria'));
    }
    if (!postcode || postcode.trim() === '') {
        return callback(new Error('Il CAP è obbligatorio'));
    }
    if (!province || province.trim() === '') {
        return callback(new Error('La provincia è obbligatoria'));
    }
    if (!municipality || municipality.trim() === '') {
        return callback(new Error('Il comune è obbligatorio'));
    }

    callback();
}

function codiceDittaINAILValidator(rule, value, callback) {
    if (!value) {
        callback();
        return;
    }

    // Check if the string is all digits separated by commas
    if (!/^[0-9]+$/.test(value)) {
        callback(new Error('Il codice ditta deve essere composto da caratteri numerici'));
        // Check if all values are 8 digits
    } else if (value.length !== 8) {
        callback(new Error('Il codice ditta deve essere composto da 8 caratteri numerici'));
    } else {
        callback();
    }
}

function PATValidator(rule, value, callback) {
    if (!value) {
        callback();
        return;
    }

    // Check if the string is all digits separated by commas
    if (!/^[0-9]+$/.test(value)) {
        callback(new Error('La PAT deve essere composto da caratteri numerici'));
        // Check if all values are 11 digits
    } else if (value.length !== 11) {
        callback(new Error('La PAT deve essere composto da 11 caratteri numerici'));
    } else {
        callback();
    }
}

function matricolaInpsValidator(rule, value, callback) {
    if (!value) {
        callback();
        return;
    }

    // Check if the string is all digits separated by commas
    if (!/^[0-9]+$/.test(value)) {
        callback(new Error('Il numero di matricola deve essere composto da caratteri numerici'));
        // Check if all values are 10 digits
    } else if (value.length !== 10) {
        callback(new Error('Il numero di matricola deve essere composto da 10 caratteri numerici'));
    } else {
        callback();
    }
}

function matricoleInpsValidator(rule, value, callback) {
    if (!value) {
        callback();
        return;
    }

    const matricole = value.split(',');

    // Check if the string is all digits separated by commas
    if (!/^[0-9,]+$/.test(value)) {
        callback(new Error('Solo numeri separati da virgole (senza spazi)'));
        // Check if all values are 10 digits
    } else if (matricole.some(matricola => matricola.length !== 10)) {
        callback(new Error('Inserire 10 caratteri per ogni matricola INPS'));
    } else {
        callback();
    }
}

function cscValidator(rule, value, callback) {
    if (!value) {
        return callback();
    }
    if (!/^[0-9]+$/.test(value)) {
        callback(new Error('Il CSC deve essere composto da caratteri numerici'));
    } else if (value.length !== 5) {
        callback(new Error('Il CSC deve essere composto da 5 cifre'));
    } else {
        callback();
    }
}

function dateGreaterThanValidator(rule, value, callback) {
    if (!value) {
        return callback();
    }

    const {date, message} = rule;

    const valueDate = parseDatetime(value);

    if (valueDate <= date) {
        callback(new Error(message));
    }

    callback();
}

function dateLowerThanValidator(rule, value, callback) {
    if (!value) {
        return callback();
    }

    const {date, message} = rule;

    const valueDate = parseDatetime(value);

    if (valueDate >= date) {
        callback(new Error(message));
    }

    callback();
}

const durationStringGreaterThan = (rule, value, callback) => {
    if (!value) {
        callback();
        return;
    }

    const parsed = parseTimeIntervalString(value);
    const {number, message} = rule;

    if (!parsed || parsed?.hours < number) {
        callback(new Error(message));
        return;
    }

    callback();
};

function isBirthDateAndPlaceValidityError(validator) {
    // The BIRTDATE typo comes from the library, and these strings are hardcoded as we cannot import them
    return (
        Object.keys(validator.errors).length === 2 &&
        validator.errors.date === 'BIRTHDATE_OUT_OF_BIRTH_PLACE_LIFE_RANGE' &&
        validator.errors.place === 'PLACE_EXPIRED_OR_NOT_YET_CREATED_ON_BIRTDATE'
    );
}

function ssnValidator(rule, value, callback) {
    if (!value) {
        return callback();
    }

    const validator = CodiceFiscaleUtils.Validator.codiceFiscale(value);

    if (!validator.valid && !isBirthDateAndPlaceValidityError(validator)) {
        callback(new Error('Codice fiscale non valido'));
    }

    callback();
}

function ssnWithNameValidator(rule, value, callback) {
    if (!value) {
        callback(new Error('Codice fiscale non inserito'));
    }

    if (!rule.firstName || !rule.lastName) {
        callback(new Error('Nome o cognome non inseriti'));
    }

    const validator = CodiceFiscaleUtils.Validator.codiceFiscale(value);

    if (!validator.valid && !isBirthDateAndPlaceValidityError(validator)) {
        callback(new Error('Codice fiscale non valido'));
    }

    const firstNameWithoutSpaces = rule.firstName.replace(/\s/g, '');
    const lastNameWithoutSpaces = rule.lastName.replace(/\s/g, '');

    /**
     * CodiceFiscaleUtils.Validator[matchFirstName|matchLastName]
     * does not handle names with less than 3 characters so we need to skip the
     * validation for this case
     */
    if (
        (firstNameWithoutSpaces.length >= 3 && !validator.matchFirstName(firstNameWithoutSpaces)) ||
        (lastNameWithoutSpaces.length >= 3 && !validator.matchLastName(lastNameWithoutSpaces))
    ) {
        return callback(new Error('Il codice fiscale non corrisponde con nome e cognome inseriti'));
    }

    callback();
}

const ssnFormat = {
    type: 'string',
    trigger: 'change',
    validator: ssnValidator,
};

const mandatorySsn = {
    required: true,
    type: 'string',
    message: 'Inserisci il codice fiscale',
    trigger: 'change',
};

const ssnRules = [ssnFormat, mandatorySsn];

function placeholderValidator(rule, value, callback) {
    if (!value) {
        return callback();
    }

    const squareBracketRegExp = /\[(.*?)\]/;
    if (squareBracketRegExp.test(value)) {
        return callback(new Error('Sono ancora presenti dei valori temporanei racchiusi tra parentesi quadre'));
    }

    callback();
}

function numberMustBePositiveValidator(rule, value, callback) {
    if (value < 0) {
        callback(new Error('Il valore deve essere positivo'));
    }
    callback();
}

function isValidInteger(rule, value, callback) {
    const isValid = !isNaN(value) && Number.isInteger(Number.parseFloat(value));

    if (!isValid) {
        return callback(new Error('Inserire un valore intero'));
    }

    callback();
}

const INVALID_VALUE = '__INVALID__';

const isInvalidValue = value => value === INVALID_VALUE;

const excludeInvalidValidator = message => (rule, value, callback) => {
    if (isInvalidValue(value)) {
        callback(new Error(message));
        return;
    }

    callback();
};

export {
    amountValidator,
    bicRules,
    dayOfMonthValidator,
    emailRules,
    ibanRules,
    optionalEmailRules,
    optionalIbanRules,
    optionalBicRules,
    percentageValidator,
    requiredFieldRule,
    addressFormRules,
    addressValidator,
    optionalPhoneNumberRules,
    phoneNumberRules,
    codiceDittaINAILValidator,
    PATValidator,
    matricolaInpsValidator,
    matricoleInpsValidator,
    cscValidator,
    dateGreaterThanValidator,
    dateLowerThanValidator,
    phoneNumberFormatValidator,
    durationStringGreaterThan,
    ssnRules,
    ssnWithNameValidator,
    placeholderValidator,
    numberMustBePositiveValidator,
    isValidInteger,
    excludeInvalidValidator,
    INVALID_VALUE,
    isInvalidValue,
    timeFormatValidator,
    ssnValidator,
};
