import ContractorApiCommon from '@jetCommon/api/contractor.js';

export default class ContractorApi extends ContractorApiCommon {
    /**
     * Disable the contractor collaboration
     * @param {number} contractorId - The contractor ID
     * @returns {Promise<Response>} The response from the API
     */
    disableContractorCollaboration(contractorId, params = {}) {
        return this.apiPatch(`${contractorId}/disable_collaboration/`, params);
    }

    /**
     * Activate a new contractor collaboration
     * @param {number} contractorId - The contractor ID
     * @returns {Promise<Response>} The response from the API
     */
    activateNewContractorCollaboration(contractorId, params = {}) {
        return this.apiPatch(`${contractorId}/activate_new_collaboration/`, params);
    }

    /**
     * Archive a contractor
     * @param {number} contractorId - The contractor ID
     * @returns {Promise<Response>} The response from the API
     */
    archiveContractor(contractorId, params = {}) {
        return this.apiPatch(`${contractorId}/archive_contractor/`, params);
    }

    /**
     * Reset a contractor
     * @param {number} contractorId - The contractor ID
     * @returns {Promise<Response>} The response from the API
     */
    resetContractor(contractorId, params = {}) {
        return this.apiPatch(`${contractorId}/reset_contractor/`, params);
    }
}
