import {loadStripe} from '@stripe/stripe-js';
import settings from '@jetCommon/env';

const sharedStripe = ref(null);
const sharedIsLoad = ref(false);

export function useStripe() {
    const {$jetMessage} = inject('jet');
    function initializeStripe() {
        if (!sharedStripe.value && !sharedIsLoad.value) {
            loadStripe(settings.STRIPE_PUBLISHABLE_KEY, {locale: 'it'})
                .then(loadedStripe => {
                    sharedStripe.value = loadedStripe;
                    sharedIsLoad.value = true;
                })
                .catch(error => {
                    sharedIsLoad.value = false;
                    $jetMessage.error(error);
                });
        }
    }
    initializeStripe();
    return {
        sharedIsLoad,
        sharedStripe,
    };
}
