<script setup>
    import {ONBOARDING_TASK_CODENAME, ONBOARDING_TASK_STATUS} from '@jetCommon/constants/onboarding.js';
    import {useOnboarding} from '@/features/onboarding/composables/onboarding.js';

    import OnboardingTaskItem from '@/features/onboarding/components/tasks/base/OnboardingTaskItem.vue';
    import SepaMandateIban from '@jetCommon/components/setup/SepaMandateIban.vue';

    const {$api} = inject('jet');
    const {fetchAll, taskByCodename, companyId} = useOnboarding();

    const sepaMandateIban = ref(null);
    const task = computed(() => taskByCodename.value[ONBOARDING_TASK_CODENAME.IBAN]);

    const metaText = computed(() => {
        return task.value?.submitted_data?.iban ? `●●●● ${task.value?.submitted_data?.iban}` : null;
    });

    const actions = computed(() => {
        const text = task.value.status === ONBOARDING_TASK_STATUS.TODO ? 'Compila' : 'Modifica';
        const confirm_button_text = task.value.status === ONBOARDING_TASK_STATUS.TODO ? 'Salva' : 'Modifica';
        const compileForm = {
            main: true,
            text,
            dialog: {
                title: 'IBAN per i pagamenti',
                name: 'main',
                confirm_button_text,
                show_confirm_button: true,
                async confirm_callback() {
                    try {
                        const response = await sepaMandateIban.value.submit();
                        if (response?.data?.last4_iban) {
                            $api.companyOnboardingTasks
                                .submitData(task.value.id, {iban: response.data.last4_iban})
                                .then(fetchAll)
                                .catch($api.end);
                        }
                    } catch (error) {
                        $api.end(error);
                    }
                },
            },
        };
        const isEditable =
            task.value.status === ONBOARDING_TASK_STATUS.TODO ||
            task.value.status === ONBOARDING_TASK_STATUS.UNDER_APPROVAL;

        return isEditable ? [compileForm] : [];
    });
</script>

<template>
    <OnboardingTaskItem
        :actions="actions"
        :codename="ONBOARDING_TASK_CODENAME.IBAN"
        :meta-text="metaText"
        title="IBAN per i pagamenti">
        <template #dialogMainBody>
            <SepaMandateIban ref="sepaMandateIban" :company-id="companyId" />
        </template>
    </OnboardingTaskItem>
</template>
