<script setup>
    import {COMPANY_HEADQUARTERS_KIND} from '@jetCommon/constants/company-headquarters-policy-constants.js';
    import {HTTP_400_BAD_REQUEST} from '@jetCommon/constants/http-status.js';
    import {addressFormRules, requiredFieldRule} from '@jetCommon/helpers/validators.js';

    import CompanyHeadquartersPolicyItem from '@jetCommon/components/onboarding/CompanyHeadquartersPolicyItem.vue';
    import JetButton from '@jetDS/components/JetButton.vue';
    import JetCallout from '@jetDS/components/JetCallout.vue';
    import JetDivider from '@jetDS/components/JetDivider.vue';
    import JetForm from '@jetDS/components/JetForm.vue';
    import JetSwitch from '@jetDS/components/JetSwitch.vue';

    const props = defineProps({
        readOnly: {
            type: Boolean,
            default: false,
        },
        task: {
            type: Object,
            required: true,
        },
        companyId: {
            type: [String, Number],
            required: true,
        },
    });

    const emit = defineEmits(['submitted']);

    const {$api, $jetMessage} = inject('jet');
    const formRef = ref(null);

    const formData = reactive({
        legal_company_headquarters_policy: props.task.submitted_data?.legal_company_headquarters_policy || {
            kind: COMPANY_HEADQUARTERS_KIND.LEGAL,
            name: 'Sede legale',
            address: {
                country: 'IT',
            },
            employees: [],
            company: props.companyId,
        },
        operative_company_headquarters_policies:
            props.task.submitted_data?.operative_company_headquarters_policies || [],
    });

    watch(
        () => formData.legal_company_headquarters_policy,
        legal_company_headquarters_policy => {
            if (!legal_company_headquarters_policy.company) {
                legal_company_headquarters_policy.company = props.companyId;
            }
            if (!legal_company_headquarters_policy.employees) {
                legal_company_headquarters_policy.employees = [];
            }
        },
        {once: true},
    );

    const hasOnlyLegalHeadquarters = ref(formData.operative_company_headquarters_policies.length === 0);

    const companyHeadquartersPolicyFormRules = {
        name: [requiredFieldRule],
        address: addressFormRules,
        patron_saint: [requiredFieldRule],
        additional_holiday: [],
    };
    const formRules = computed(() => ({
        legal_company_headquarters_policy: companyHeadquartersPolicyFormRules,
    }));

    const initialErrorData = reactive({
        legal_company_headquarters_policy: {
            patron_saint: null,
            additional_holiday: null,
            address: {
                street: null,
                country: null,
                province: null,
                municipality: null,
                postcode: null,
            },
        },
    });
    const errorData = reactive({...initialErrorData});

    function resetErrorData() {
        Object.assign(errorData, initialErrorData);
    }

    async function submit() {
        if (props.readOnly) {
            $jetMessage.warning('Non puoi modificare i dati in questo momento.');
            return;
        }
        resetErrorData();
        const formEl = formRef.value.getElFormRef();
        const valid = await formEl.validate().catch(() => false);
        if (valid) {
            $api.companyOnboardingTasks
                .noMsgForStatusCodes([HTTP_400_BAD_REQUEST])
                .submitData(props.task.id, formData)
                .then(() => emit('submitted'))
                .catch(error => {
                    // representation of server side errors for each form field
                    Object.entries(error.response.data).forEach(entry => {
                        const [key, value] = entry;
                        if (Array.isArray(value)) {
                            // ToDo: for better ux implement error handling for
                            // dynamically-generated nested fields
                            // Handle error message with a popup instead of inline
                            return $jetMessage.error('Inserisci i dati mancanti');
                        }
                        errorData[key] = value;
                    });
                })
                .catch($api.end);
        }
    }

    function addOperativeCompanyHeadquartersPolicy() {
        formData.operative_company_headquarters_policies.push({
            kind: COMPANY_HEADQUARTERS_KIND.OPERATIVE,
            address: {
                country: 'IT',
            },
            employees: [],
            company: props.companyId,
        });
    }

    function deleteOperativeCompanyHeadquartersPolicy(index) {
        formData.operative_company_headquarters_policies.splice(index, 1);
        if (formData.operative_company_headquarters_policies.length === 0) {
            hasOnlyLegalHeadquarters.value = true;
        }
    }

    function toggleHasOnlyLegalHeadquarters(value) {
        if (!value) {
            addOperativeCompanyHeadquartersPolicy();
        }
    }

    defineExpose({
        formData,
        submit,
    });
</script>

<template>
    <JetForm ref="formRef" :rules="formRules" :model="formData">
        <h5 class="mb-3">Sede legale</h5>
        <CompanyHeadquartersPolicyItem
            v-model="formData.legal_company_headquarters_policy"
            :disabled="readOnly"
            :errors="errorData"
            props-prefix="legal_company_headquarters_policy" />
        <JetSwitch
            v-model="hasOnlyLegalHeadquarters"
            active-text="La sede legale corrisponde alla sede operativa"
            :disabled="readOnly || formData.operative_company_headquarters_policies.length > 0"
            @update:model-value="toggleHasOnlyLegalHeadquarters" />

        <div v-if="!hasOnlyLegalHeadquarters">
            <div v-for="(policy, index) in formData.operative_company_headquarters_policies" :key="index">
                <JetDivider />
                <h5 class="mb-3">Sede operativa</h5>
                <CompanyHeadquartersPolicyItem
                    v-model="formData.operative_company_headquarters_policies[index]"
                    :disabled="readOnly"
                    :props-prefix="`operative_company_headquarters_policies.${index}`" />

                <JetButton
                    v-if="!readOnly"
                    icon-name="times"
                    size="small"
                    @click="deleteOperativeCompanyHeadquartersPolicy(index)">
                    Elimina
                </JetButton>
            </div>
            <div v-if="!readOnly">
                <JetDivider />
                <JetButton icon-name="plus" size="small" @click="addOperativeCompanyHeadquartersPolicy">
                    Aggiungi altra sede operativa
                </JetButton>
            </div>
        </div>

        <JetDivider />
        <JetCallout class="mt-4" type="warning" plain>
            Una volta salvati questi campi non sarà più possibile modificarli. Se hai fatto un errore contatta
            l'assistenza.
        </JetCallout>
    </JetForm>
</template>
