import {inject} from 'vue';
import {useQuery} from '@tanstack/vue-query';

export const useCompanyAbsenceRequestKindsQuery = queryParams => {
    const {$api} = inject('jet');

    return useQuery({
        queryKey: ['getCompanyAbsenceRequestKinds', queryParams],
        queryFn: () => {
            return $api.companyAbsenceRequestKinds
                .list(queryParams)
                .then(response => response.data)
                .catch($api.end);
        },
        placeholderData: [],
    });
};
