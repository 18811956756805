const CONTRACT_KIND_LABEL = {
    COLLABORATOR: 'Co.co.co.',
    INTERN: 'Tirocinio / stage extra-curriculare',
    EMPLOYEE: 'Dipendente',
    APPRENTICESHIP: 'Apprendista',
    ON_CALL: 'Dipendente a chiamata',
    ADMINISTRATOR: 'Amministratore o consigliere',
    CONTRACTOR: 'Professionista esterno',
};

const CONTRACT_KIND_LABEL_PLURAL = {
    COLLABORATOR: 'Co.co.co.',
    INTERN: 'Tirocini / stage extra-curriculari',
    EMPLOYEE: 'Dipendenti',
    APPRENTICESHIP: 'Apprendisti',
    ON_CALL: 'Dipendenti a chiamata',
    ADMINISTRATOR: 'Amministratori o consiglieri',
    CONTRACTOR: 'Professionisti esterni',
};

const CONTRACT_KIND = {
    COLLABORATOR: 'COLLABORATOR',
    INTERN: 'INTERN',
    EMPLOYEE: 'EMPLOYEE',
    APPRENTICESHIP: 'APPRENTICESHIP',
    ON_CALL: 'ON_CALL',
    ADMINISTRATOR: 'ADMINISTRATOR',
    CONTRACTOR: 'CONTRACTOR',
};

const CONTRACT_STATUS = {
    NO_CONTRACT: 'NO_CONTRACT',
    IN_FORCE: 'IN_FORCE',
    INCOMING: 'INCOMING',
    TERMINATED: 'TERMINATED',
};

const IN_FORCE_CONTRACT_SUBSTATUS = {
    FIRST_MONTH: 'FIRST_MONTH',
    LAST_MONTH: 'LAST_MONTH',
};

const TERMINATED_CONTRACT_SUBSTATUS = {
    IN_PROGRESS: 'IN_PROGRESS',
};

const EMPLOYEE_STATUS = {
    NOT_SYNCED: 'NOT_SYNCED',
    NOT_INVITED: 'NOT_INVITED',
    INVITED: 'INVITED',
    INVITATION_EXPIRED: 'INVITATION_EXPIRED',
    REGISTERED: 'REGISTERED',
};

const STATUS_DATA_MAP = {
    [EMPLOYEE_STATUS.NOT_SYNCED]: {
        tagType: 'warning',
        tagText: 'Da sincronizzare',
    },
    [EMPLOYEE_STATUS.NOT_INVITED]: {
        tagType: '',
        tagText: 'Da invitare',
    },
    [EMPLOYEE_STATUS.INVITED]: {
        tagType: 'info',
        tagText: 'Invitato',
    },
    [EMPLOYEE_STATUS.INVITATION_EXPIRED]: {
        tagType: 'danger',
        tagText: 'Invito scaduto',
    },
    [EMPLOYEE_STATUS.REGISTERED]: {
        tagType: 'success',
        tagText: 'Registrato',
    },
};

const GROSS_SALARY_COLUMN_TOOLTIP_TEXT = `La retribuzione lorda visualizzata è stimata
     sulla base degli elementi retributivi mensili, che possono variare ogni mese.
     Questo valore è indicativo e potrebbe differire fino al 5%
     rispetto a quello pattuito nel contratto.`;

export {
    CONTRACT_KIND_LABEL,
    CONTRACT_KIND_LABEL_PLURAL,
    CONTRACT_KIND,
    CONTRACT_STATUS,
    EMPLOYEE_STATUS,
    IN_FORCE_CONTRACT_SUBSTATUS,
    TERMINATED_CONTRACT_SUBSTATUS,
    GROSS_SALARY_COLUMN_TOOLTIP_TEXT,
    STATUS_DATA_MAP,
};
