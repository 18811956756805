<script setup>
    import {ONBOARDING_TASK_CODENAME, ONBOARDING_TASK_STATUS} from '@jetCommon/constants/onboarding.js';
    import {useOnboarding} from '@/features/onboarding/composables/onboarding.js';

    import ExtraMonthlyPayForm from '@jetCommon/components/onboarding/ExtraMonthlyPayForm.vue';
    import JetCallout from '@jetDS/components/JetCallout.vue';
    import OnboardingTaskItem from '@/features/onboarding/components/tasks/base/OnboardingTaskItem.vue';

    const {fetchAll, taskByCodename} = useOnboarding();
    const task = computed(() => taskByCodename.value[ONBOARDING_TASK_CODENAME.EXTRA_MONTHLY_PAY]);
    const taskIsToDo = computed(() => task.value.status === ONBOARDING_TASK_STATUS.TODO);

    const formRef = ref(null);

    const actions = computed(() => {
        return [
            {
                main: true,
                text: taskIsToDo.value ? 'Compila' : 'Visualizza',
                dialog: {
                    title: 'Tredicesima e quattordicesima',
                    name: 'main',
                    confirm_button_text: 'Salva',
                    confirm_callback() {
                        formRef.value.submit();
                    },
                    show_confirm_button: taskIsToDo.value,
                },
            },
        ];
    });
</script>

<template>
    <OnboardingTaskItem
        :actions="actions"
        description="Imposta le informazioni necessarie per l'elaborazione dei cedolini."
        :codename="ONBOARDING_TASK_CODENAME.EXTRA_MONTHLY_PAY"
        title="Tredicesima e quattordicesima">
        <template #dialogMainBody>
            <ExtraMonthlyPayForm ref="formRef" :read-only="!taskIsToDo" :task="task" @submitted="fetchAll()" />
            <JetCallout class="mt-4" type="warning" plain>
                Una volta salvati questi campi non sarà più possibile modificarli. Se hai fatto un errore contatta
                l'assistenza.
            </JetCallout>
        </template>
    </OnboardingTaskItem>
</template>
