<script setup>
    import {isoDateToItaVerbose} from '@jetCommon/helpers/date.js';
    import {useDesignSystemTools} from '@jetCommon/composables/design-system-tools';

    import JetButton from '@jetDS/components/JetButton.vue';
    import JetCheckbox from '@jetDS/components/JetCheckbox.vue';
    import JetCurrencyInput from '@jetDS/components/JetCurrencyInput.vue';
    import JetDatePicker from '@jetDS/components/JetDatePicker.vue';
    import JetForm from '@jetDS/components/JetForm.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import JetInfoLine from '@jetDS/components/JetInfoLine.vue';
    import JetInput from '@jetDS/components/JetInput.vue';
    import JetSelect from '@jetDS/components/JetSelect.vue';

    const props = defineProps({
        modelValue: {
            type: [String, Number, Boolean, null],
            required: true,
        },
        isCurrency: {
            type: Boolean,
            default: false,
        },
        precision: {
            type: Number,
            default: 0,
        },
        inputType: {
            type: String,
            default: 'text',
        },
        inputArgs: {
            type: Object,
            default: () => ({}),
        },
        // Must be a callable with single parameter
        apiUpdateMethod: {
            type: Function,
            default: () => {},
        },
        inputValueRules: {
            type: Array,
            default: () => [
                {
                    required: true,
                    message: 'Inserisci un valore.',
                    trigger: 'change',
                },
            ],
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        dateDisplayOptions: {
            type: Object,
            default: () => null,
        },
        description: {
            type: String,
            default: '',
        },
        enableDateYearArrowNavigation: {
            type: Boolean,
            default: false,
        },
        labelTooltip: {
            type: String,
            default: null,
        },
    });
    const emit = defineEmits(['update:modelValue']);
    const {isMdAndUp} = useDesignSystemTools();

    const {$api} = inject('jet');

    const defaultInputArgsByType = {
        date: {
            'enable-year-arrow-navigation': true,
            size: 'default',
        },
    };
    const parsedInputArgs = computed(() => {
        return props.inputArgs || defaultInputArgsByType[props.inputType] || {};
    });

    const formItemRef = ref(null);
    const editFormRef = ref();
    const editForm = reactive({
        inputValue: props.modelValue,
    });
    watch(
        () => props.modelValue,
        () => {
            editForm.inputValue = props.modelValue;
        },
    );

    const editFormRules = computed(() => {
        return {inputValue: props.inputValueRules};
    });

    const loading = ref(false);
    const editMode = ref(false);
    const initialForm = reactive({});
    function toggleEditMode() {
        editMode.value = !editMode.value;
    }
    watch(editMode, () => {
        if (editMode.value) {
            initialForm.inputValue = editForm.inputValue;

            // This is to autoset a value for a checkbox, as otherwise an
            // empty checkbox returns a validation error instead of being saved
            if (props.inputType === 'boolean' && editForm.inputValue === null) {
                editForm.inputValue = false;
            }

            // since we use v-show on the lineEdit slot of JetInfoLine,
            // we need to await that v-show is true (display is not none) to focus
            // textareas are also taken into account
            nextTick(() => {
                (
                    formItemRef.value.$el.getElementsByTagName('input')[0] ||
                    formItemRef.value.$el.getElementsByTagName('textarea')[0]
                )?.focus();
            });
        }
    });

    const formattedValue = computed(() => {
        if (typeof props.modelValue === 'number') {
            return props.modelValue ?? '-';
        } else if (props.inputType === 'date') {
            if (!props.modelValue) {
                return '-';
            }
            return isoDateToItaVerbose(props.modelValue, props.dateDisplayOptions);
        } else if (props.inputType === 'boolean') {
            if (props.modelValue === null) {
                return '-';
            } else {
                return props.modelValue;
            }
        } else {
            return props.modelValue || '-';
        }
    });

    async function submitForm(form) {
        const valid = await form
            .getElFormRef()
            .validate()
            .catch(() => false);
        if (valid) {
            loading.value = true;
            props
                .apiUpdateMethod(editForm.inputValue)
                .then(() => {
                    emit('update:modelValue', editForm.inputValue);
                    initialForm.value = null;
                    toggleEditMode();
                })
                .finally(() => {
                    loading.value = false;
                })
                .catch($api.end);
        }
    }

    function abortChange() {
        editForm.inputValue = initialForm.inputValue;
        toggleEditMode();
    }

    const actionLabel = computed(() => {
        if (props.readonly) {
            return '';
        }

        return props.modelValue === '' || props.modelValue === null ? 'Inserisci' : 'Modifica';
    });
</script>

<template>
    <JetInfoLine
        v-model:edit-mode="editMode"
        :description="description"
        :action-label="actionLabel"
        :label-tooltip="labelTooltip">
        <template #lineLabel>
            <slot name="lineLabel" />
        </template>
        <template #lineDescription>
            <slot name="lineDescription" />
        </template>
        <slot>
            {{ formattedValue }}
        </slot>
        <template #lineEdit>
            <JetForm
                ref="editFormRef"
                :model="editForm"
                :rules="editFormRules"
                class="input-info-line__inline-edit"
                @submit.prevent>
                <JetFormItem
                    ref="formItemRef"
                    class="input-info-line__inline-edit-form-item"
                    :class="isMdAndUp ? 'm-0' : 'mt-1 mx-0 mb-2'"
                    prop="inputValue">
                    <JetCurrencyInput
                        v-if="isCurrency"
                        v-model="editForm.inputValue"
                        size="default"
                        :precision="precision"
                        :class="{'pr-4': isMdAndUp}"
                        v-bind="parsedInputArgs" />
                    <JetDatePicker
                        v-else-if="inputType === 'date'"
                        v-model="editForm.inputValue"
                        :enable-year-arrow-navigation="enableDateYearArrowNavigation"
                        :class="{'pr-4': isMdAndUp}"
                        v-bind="parsedInputArgs" />
                    <JetCheckbox
                        v-else-if="inputType === 'boolean'"
                        v-model="editForm.inputValue"
                        v-bind="parsedInputArgs" />
                    <JetSelect
                        v-else-if="inputType === 'select'"
                        v-model="editForm.inputValue"
                        v-bind="parsedInputArgs" />
                    <JetInput
                        v-else
                        v-model="editForm.inputValue"
                        :type="inputType"
                        :class="{'pr-4': isMdAndUp}"
                        v-bind="parsedInputArgs" />
                </JetFormItem>
                <div class="input-info-line__inline-edit-actions">
                    <JetButton
                        type="primary"
                        :class="isMdAndUp ? 'ml-0' : 'ml-4'"
                        native-type="submit"
                        :disabled="loading"
                        @click="submitForm(editFormRef)">
                        Salva
                    </JetButton>
                    <JetButton :disabled="loading" @click="abortChange">Annulla</JetButton>
                </div>
            </JetForm>
        </template>
        <template #additionalActions>
            <slot name="additionalActions" />
        </template>
    </JetInfoLine>
</template>

<style scoped lang="scss">
    @use '@jetDS/scss/_media-queries.scss' as *;

    .input-info-line {
        &__inline-edit {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;

            @include media-query('md-and-up') {
                flex-flow: row nowrap;
            }

            &-actions {
                display: flex;
                flex-direction: row-reverse;
                flex: 1;
                align-items: flex-end;

                @include media-query('md-and-up') {
                    display: block;
                    min-width: var(--jet-info-line-edit-actions-min-width);
                }
            }

            &-form-item {
                flex-basis: 100%;
                display: inherit;

                @include media-query('md-and-up') {
                    flex-basis: 40%;
                    width: 100%;
                }
            }
        }
    }
</style>
