const PERSON_ROLE = {
    ADMIN: 'admin',
    MANAGER: 'manager',
    EMPLOYEE: 'employee',
    OPERATOR: 'operator',
    LABOUR_CONSULTANT: 'labour_consultant',
};

const PERSON_STATUS = {
    NOT_INVITED: 'NOT_INVITED',
    INVITED: 'INVITED',
    INVITATION_EXPIRED: 'INVITATION_EXPIRED',
    REGISTERED: 'REGISTERED',
};

export {PERSON_ROLE, PERSON_STATUS};
