const ABSENCES = {
    STANDARD: 'STANDARD',
    DEFERRED: 'DEFERRED',
};

const PAY_MONTH_KIND = {
    CURRENT: 'CURRENT',
    NEXT: 'NEXT',
};

const PAY_MONTH_KIND_LABEL = {
    [PAY_MONTH_KIND.CURRENT]: 'mese corrente',
    [PAY_MONTH_KIND.NEXT]: 'mese successivo',
};

const ABSENCES_LABEL_KIND = {
    STANDARD: 'Standard (Utilizzo variabili mese corrente)',
    DEFERRED: 'Differite (Utilizzo variabili mese precedente)',
};

const ABSENCES_LABEL_KIND_CHOICES = [
    {
        value: ABSENCES.STANDARD,
        display_name: ABSENCES_LABEL_KIND.STANDARD,
    },
    {
        value: ABSENCES.DEFERRED,
        display_name: ABSENCES_LABEL_KIND.DEFERRED,
    },
];

const EXTRA_MONTHLY_PAY = {
    THIRTEENTH_FOURTEENTH_STANDARD_SUPPLY: 'THIRTEENTH_FOURTEENTH_STANDARD_SUPPLY',
    THIRTEENTH_FOURTEENTH_MONTHLY_SUPPLY: 'THIRTEENTH_FOURTEENTH_MONTHLY_SUPPLY',
    THIRTEENTH_STANDARD_SUPPLY: 'THIRTEENTH_STANDARD_SUPPLY',
    THIRTEENTH_MONTHLY_SUPPLY: 'THIRTEENTH_MONTHLY_SUPPLY',
    THIRTEENTH_STANDARD_FOURTEENTH_MONTHLY_SUPPLY: 'THIRTEENTH_STANDARD_FOURTEENTH_MONTHLY_SUPPLY',
    FOURTEENTH_STANDARD_THIRTEENTH_MONTHLY_SUPPLY: 'FOURTEENTH_STANDARD_THIRTEENTH_MONTHLY_SUPPLY',
};

const EXTRA_MONTHLY_PAY_LABEL = {
    [EXTRA_MONTHLY_PAY.THIRTEENTH_FOURTEENTH_STANDARD_SUPPLY]: '13 esima e 14 esima - Erogazione Standard',
    [EXTRA_MONTHLY_PAY.THIRTEENTH_FOURTEENTH_MONTHLY_SUPPLY]: '13 esima e 14 esima - Mensilizzata',
    [EXTRA_MONTHLY_PAY.THIRTEENTH_STANDARD_SUPPLY]: '13 esima - Erogazione Standard',
    [EXTRA_MONTHLY_PAY.THIRTEENTH_MONTHLY_SUPPLY]: '13 esima - Mensilizzata',
    [EXTRA_MONTHLY_PAY.THIRTEENTH_STANDARD_FOURTEENTH_MONTHLY_SUPPLY]:
        '13 esima - Erogazione Standard | 14 esima - Mensilizzata',
    [EXTRA_MONTHLY_PAY.FOURTEENTH_STANDARD_THIRTEENTH_MONTHLY_SUPPLY]:
        '14 esima - Erogazione Standard | 13 esima - Mensilizzata',
};

const PAYROLL_ELABORATION_MODE = {
    AUTOMATIC: 'AUTOMATIC',
    MANUAL: 'MANUAL',
};

export {
    ABSENCES,
    PAY_MONTH_KIND_LABEL,
    PAY_MONTH_KIND,
    ABSENCES_LABEL_KIND,
    ABSENCES_LABEL_KIND_CHOICES,
    EXTRA_MONTHLY_PAY,
    EXTRA_MONTHLY_PAY_LABEL,
    PAYROLL_ELABORATION_MODE,
};
