import {ApiBase} from '@jetCommon/api/base.js';

export default class AbsenceRequestKindSettingsApiCommon extends ApiBase {
    static resourceName = 'absence_request_kind_settings';

    attachmentChoices(params) {
        return this.apiGet('attachment_choices/', {params});
    }

    getDefaultConfiguration(params) {
        return this.apiGet('default_configuration/', {params});
    }

    getMandatorySettings() {
        return this.apiGet('mandatory_settings/');
    }
}
