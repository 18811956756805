<script setup>
    import 'element-plus/es/components/option/style/index';
    import 'element-plus/es/components/select/style/index';
    import {ElOptionGroup} from 'element-plus';
    import {ElOption} from 'element-plus';
    import {ElSelect} from 'element-plus';

    import JetButton from '@jetDS/components/JetButton.vue';
    import JetIcon from '@jetDS/components/JetIcon.vue';
    import JetInput from '@jetDS/components/JetInput.vue';

    const props = defineProps({
        clearable: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false},
        fitInputWidth: {type: Boolean, default: false},
        fullWidth: {type: Boolean, default: false},
        options: {type: [Array, Function], required: true},
        grouped: {type: Boolean, default: false},
        modelValue: {type: [Array, Number, String, Boolean, null], required: true},
        multiple: {type: Boolean, default: false},
        optionHeightFluid: {type: Boolean, default: false},
        queryParams: {type: Object, default: () => ({})},
        maxCollapseTags: {type: Number, default: 1},
        maxWidth: {type: [Number, String], default: 'none'},
        withSelectAll: {type: Boolean, default: false},
        enableNewOption: {type: Boolean, default: false},
        newOptionButtonText: {type: String, default: 'Aggiungi'},
    });

    const emit = defineEmits(['update:modelValue', 'insert-new-option']);

    const value = computed({
        get() {
            return props.modelValue;
        },
        set(val) {
            emit('update:modelValue', val);
        },
    });
    const elSelectRef = ref(null);

    const style = computed(() => ({
        maxWidth: typeof props.maxWidth === 'number' ? `${props.maxWidth}px` : props.maxWidth,
    }));

    const slots = useSlots();

    const loading = ref(false);

    const internalOptions = ref([]);
    const search = ref('');
    const lastSearchQuery = ref(null);
    const checkAll = ref(false);

    const isRemote = computed(() => {
        return typeof props.options === 'function';
    });

    async function loadOptions() {
        if (isRemote.value) {
            const queryParams = {...props.queryParams, search: search.value};
            if (JSON.stringify(lastSearchQuery.value) === JSON.stringify(queryParams)) {
                return;
            }
            loading.value = true;
            internalOptions.value = await props.options(queryParams);
            lastSearchQuery.value = queryParams;
            loading.value = false;
        } else {
            internalOptions.value = props.options;
        }
    }

    function onSearch(searchText) {
        search.value = searchText || '';
    }

    watch(
        () => [props.queryParams, props.options, search.value],
        () => {
            loadOptions();
        },
        {deep: true, immediate: true},
    );

    const selectedOption = computed(() => {
        if (!internalOptions.value) {
            return null;
        }
        return (
            props.grouped
                ? internalOptions.value.reduce((carry, group) => carry.concat(group.items), [])
                : [...internalOptions.value]
        ).find(item => item.value === props.modelValue);
    });

    const hasNoOptions = computed(() => {
        return !internalOptions.value || internalOptions.value.length === 0;
    });

    const shouldDisableSelect = computed(
        () => props.disabled || (hasNoOptions.value && !search.value && !loading.value && !props.enableNewOption),
    );

    watch(value, val => {
        if (!props.withSelectAll) return;

        const hasSelectedAllOptions =
            val.length > 0 && val.length === internalOptions.value.filter(v => !v.disabled).length;

        checkAll.value = hasSelectedAllOptions;
    });

    const handleCheckAll = val => {
        if (val) {
            value.value = internalOptions.value.filter(v => !v.disabled).map(_ => _.value);
        } else {
            value.value = [];
        }
    };
    const isAddingNewOption = ref(false);
    const newOptionName = ref('');

    function onAddNewOption() {
        isAddingNewOption.value = true;
    }

    function clearNewOption() {
        newOptionName.value = '';
        isAddingNewOption.value = false;
    }

    function onConfirmNewOption() {
        if (newOptionName.value) {
            emit('insert-new-option', newOptionName.value);
            clearNewOption();
        }
    }

    function blur() {
        elSelectRef.value.blur();
    }

    defineExpose({
        blur,
    });
</script>

<template>
    <ElSelect
        ref="elSelectRef"
        v-model="value"
        class="jet-select"
        :class="{'jet-select--full-width': fullWidth}"
        :clearable="clearable"
        collapse-tags
        :max-collapse-tags="maxCollapseTags"
        :disabled="shouldDisableSelect"
        :fit-input-width="fitInputWidth"
        :multiple="multiple"
        :remote="isRemote"
        remote-show-suffix
        :reserve-keyword="false"
        :remote-method="onSearch"
        no-data-text="Nessuna corrispondenza trovata"
        :loading="loading">
        <template v-if="withSelectAll" #header>
            <div class="jet-select__header pr-3" @click="() => handleCheckAll(!checkAll)">
                Tutti
                <JetIcon
                    v-if="checkAll"
                    class="jet-select-option__selected-icon xs"
                    icon-style="regular"
                    name="check" />
            </div>
        </template>

        <template v-if="selectedOption && slots.selectedOptionPrefix" #prefix>
            <slot name="selectedOptionPrefix" v-bind="{option: selectedOption}" />
        </template>
        <template v-if="grouped">
            <ElOptionGroup
                v-for="group in internalOptions"
                :key="group.label"
                :label="group.items.length ? group.label : null"
                class="jet-select-option-group base medium p-0">
                <ElOption
                    v-for="option in group.items"
                    :key="`${group.label}::${option.value}`"
                    :disabled="option.disabled"
                    :label="option.display_name"
                    :value="option.value"
                    class="jet-select-option base medium py-2 px-3"
                    :class="{
                        'jet-select-option--has-prefix': slots.optionPrefix,
                        'jet-select-option--height-fluid': optionHeightFluid,
                    }">
                    <slot name="optionPrefix" v-bind="{option}" />
                    <span class="jet-select-option__content" :class="{'ml-2': slots.optionPrefix}">
                        <span class="jet-select-option__content__main">
                            {{ option.display_name }}
                            <slot name="optionDisplayExtra" v-bind="{option}" />
                        </span>
                        <span v-if="$slots.optionCaption" class="jet-select-option__content__caption">
                            <slot name="optionCaption" v-bind="{option}" />
                        </span>
                    </span>
                    <JetIcon class="jet-select-option__selected-icon xs" icon-style="regular" name="check" />
                </ElOption>
            </ElOptionGroup>
        </template>
        <template v-else>
            <ElOption
                v-for="option in internalOptions"
                :key="option.value"
                :disabled="option.disabled"
                :label="option.display_name"
                :value="option.value"
                class="jet-select-option py-2 px-3 base medium"
                :class="{
                    'jet-select-option--has-prefix': slots.optionPrefix,
                    'jet-select-option--height-fluid': optionHeightFluid,
                }">
                <slot name="optionPrefix" v-bind="{option}" />
                <span class="jet-select-option__content" :class="{'ml-2': slots.optionPrefix}">
                    <span class="jet-select-option__content__main">
                        {{ option.display_name }}
                        <slot name="optionDisplayExtra" v-bind="{option}" />
                    </span>
                    <span v-if="$slots.optionCaption" class="jet-select-option__content__caption">
                        <slot name="optionCaption" v-bind="{option}" />
                    </span>
                </span>
                <JetIcon class="jet-select-option__selected-icon xs" icon-style="regular" name="check" />
            </ElOption>
        </template>

        <template v-if="enableNewOption" #footer>
            <JetButton v-if="!isAddingNewOption" @click="onAddNewOption">
                {{ newOptionButtonText }}
            </JetButton>
            <template v-else>
                <JetInput v-model="newOptionName" class="mb-2" placeholder="Inserisci un nuovo valore" size="default" />
                <JetButton type="primary" @click="onConfirmNewOption">Conferma</JetButton>
                <JetButton @click="clearNewOption">Annulla</JetButton>
            </template>
        </template>
    </ElSelect>
</template>

<style scoped lang="scss">
    .jet-select {
        max-width: v-bind('style.maxWidth');

        &--full-width {
            width: 100%;
            box-sizing: border-box;
        }

        &__prefix-icon {
            width: 16px;
        }

        &__header {
            cursor: pointer;
            font-weight: var(--jet-font-weight-regular-medium);
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    :deep() {
        input {
            font-family: inherit;
            font-weight: var(--jet-font-weight-regular-medium);
        }

        // Default borders override (box-shadow -> proper border)
        .el-input__wrapper,
        .el-input__wrapper:hover,
        .el-input__wrapper:active,
        .el-input.is-focus .el-input__wrapper,
        .el-input.is-focus .el-input__wrapper:hover,
        .el-input.is-focus .el-input__wrapper:active,
        .el-input.is-disabled .el-input__wrapper,
        .el-input.is-disabled .el-input__wrapper:hover,
        .el-input.is-disabled .el-input__wrapper:active {
            box-shadow: none !important;
            border-style: var(--jet-input-border-style);
            border-width: var(--jet-input-border-width);
        }

        .el-input__wrapper,
        .el-input__wrapper:hover,
        .el-input__wrapper:active {
            border-color: var(--el-border-color-darker);
        }

        .el-input.is-focus .el-input__wrapper,
        .el-input.is-focus .el-input__wrapper:hover,
        .el-input.is-focus .el-input__wrapper:active {
            border-color: var(--el-color-primary);
        }

        .el-input.is-disabled .el-input__wrapper,
        .el-input.is-disabled .el-input__wrapper:hover,
        .el-input.is-disabled .el-input__wrapper:active {
            border-color: var(--el-border-color-lighter);
        }

        .el-input.is-disabled .el-input__wrapper .el-select__caret {
            color: var(--el-text-color-disabled);
        }
    }

    .jet-select-option.el-select-dropdown__item:active {
        background-color: var(--el-fill-color-darker);
    }

    .jet-select-option {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;

        &--height-fluid {
            height: auto;
        }

        &__content {
            flex-grow: 1;
            flex-wrap: wrap;
            &__main,
            &__caption {
                display: block;
                width: 100%;
            }

            &__caption {
                color: var(--jet-text-color-placeholder);
                font-size: var(--jet-font-size-xs);
                font-weight: var(--jet-font-weight-regular-medium);
                white-space: normal;
            }
        }

        &:not(.selected) &__selected-icon {
            display: none;
        }

        &.el-select-dropdown__item.selected {
            font-weight: inherit;
        }
    }

    .jet-select-option :deep(svg) {
        width: var(--jet-select-prefix-icon-size);
    }

    .jet-select-option-group {
        &:not(:last-of-type)::after {
            display: none;
        }

        &:deep(.el-select-group__title) {
            font-size: inherit;
            padding: var(--jet-sp-2) var(--jet-sp-3) var(--jet-sp-0) var(--jet-sp-3);
        }
    }
</style>
