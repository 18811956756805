<script setup>
    import {WEEKLY_TIME_TOLERANCE_MINUTE_CHOICES} from '@/features/company-policy/clocking/constants';
    import {sumHourStringAndMinutes} from '@jetCommon/helpers/date';

    import JetButton from '@jetDS/components/JetButton.vue';
    import JetCallout from '@jetDS/components/JetCallout.vue';
    import JetDialog from '@jetDS/components/JetDialog.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import JetSelect from '@jetDS/components/JetSelect.vue';

    const props = defineProps({
        /**
         * Object representing the allowed working minutes.
         *
         * @property {number|null} weekly_working_hours_notification_lacking_minutes_threshold - The number of minutes allowed for lacking working time.
         * @property {number|null} weekly_working_hours_notification_additional_minutes_threshold - The number of minutes allowed for additional working time.
         */
        weeklyWorkingHoursNotificationData: {
            type: Object,
            required: true,
        },
    });

    const emit = defineEmits(['update:weeklyWorkingHoursNotificationData', 'update:showDialog']);

    const dialogRef = ref(null);

    const dialogData = ref({
        ...props.weeklyWorkingHoursNotificationData,
    });

    function onConfirm() {
        emit('update:weeklyWorkingHoursNotificationData', dialogData.value);
        dialogRef.value.closeDialog();
    }

    const calloutText = computed(() => {
        if (
            dialogData.value.weekly_working_hours_notification_lacking_minutes_threshold === null &&
            dialogData.value.weekly_working_hours_notification_additional_minutes_threshold === null
        ) {
            return 'Il sistema non invierà notifiche sul monte ore.';
        }

        const prefix =
            "Ipotizzando un monte ore settimanale di 40h il sistema segnalerà un'anomalia nel caso di monte ore";

        const timeFormat = 'HHh MMm';

        if (
            dialogData.value.weekly_working_hours_notification_additional_minutes_threshold &&
            dialogData.value.weekly_working_hours_notification_lacking_minutes_threshold
        ) {
            return `${prefix} inferiore a ${sumHourStringAndMinutes('40:00', -dialogData.value.weekly_working_hours_notification_lacking_minutes_threshold, timeFormat, true)} e superiore a ${sumHourStringAndMinutes('40:00', dialogData.value.weekly_working_hours_notification_additional_minutes_threshold, timeFormat, true)}.`;
        }
        if (dialogData.value.weekly_working_hours_notification_lacking_minutes_threshold) {
            return `${prefix} inferiore a ${sumHourStringAndMinutes('40:00', -dialogData.value.weekly_working_hours_notification_lacking_minutes_threshold, timeFormat, true)}.`;
        }
        return `${prefix} superiore a ${sumHourStringAndMinutes('40:00', dialogData.value.weekly_working_hours_notification_additional_minutes_threshold, timeFormat, true)}.`;
    });
</script>

<template>
    <JetDialog
        ref="dialogRef"
        show
        title="Notifiche sul monte ore settimanale"
        destroy-on-close
        @update:show="emit('update:showDialog', $event)">
        <template #body>
            <div class="SelectRowContainer">
                <JetFormItem label="Tolleranza in difetto">
                    <JetSelect
                        v-model="dialogData.weekly_working_hours_notification_lacking_minutes_threshold"
                        :options="WEEKLY_TIME_TOLERANCE_MINUTE_CHOICES" />
                </JetFormItem>
                <JetFormItem label="Tolleranza in eccesso">
                    <JetSelect
                        v-model="dialogData.weekly_working_hours_notification_additional_minutes_threshold"
                        :options="WEEKLY_TIME_TOLERANCE_MINUTE_CHOICES" />
                </JetFormItem>
            </div>
            <JetCallout plain type="info">
                Esempio
                <p class="sm">
                    {{ calloutText }}
                </p>
            </JetCallout>
            <JetCallout v-if="!dialogData.exit_clocking_enabled" class="mt-3" plain type="warning">
                Per ricevere notifiche sul monte ore settimanale è necessario attivare la timbratura d'uscita.
            </JetCallout>
        </template>
        <template #footer>
            <JetButton @click="$refs.dialogRef.closeDialog()">Annulla</JetButton>
            <JetButton type="primary" @click="onConfirm">Conferma</JetButton>
        </template>
    </JetDialog>
</template>

<style scoped lang="scss">
    .SelectRowContainer {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        align-items: center;
    }
</style>
