import {inject} from 'vue';
import {useQuery} from '@tanstack/vue-query';

export const useAbsenceRequestAttachmentChoicesQuery = () => {
    const {$api} = inject('jet');

    return useQuery({
        queryKey: ['getAbsenceRequestAttachmentChoices'],
        queryFn: () => {
            return $api.absenceRequestKindSettings
                .attachmentChoices()
                .then(response => response.data)
                .catch($api.end);
        },
        placeholderData: [],
    });
};
