import {ApiBase} from '@jetCommon/api/base.js';

export default class EmployeeShiftApiCommon extends ApiBase {
    static resourceName = 'employee_shifts';

    bulkPublish(data) {
        return this.apiPost(`bulk_publish/`, data);
    }

    bulkDelete(data) {
        return this.apiDelete(`bulk_delete/`, {
            data,
        });
    }

    getCalendarSetup(config) {
        return this.apiGet(`calendar_setup/`, config);
    }

    getCalendarEvents(config) {
        return this.apiGet(`calendar_events/`, config);
    }

    getCalendarScheduleInfo(config) {
        return this.apiGet(`calendar_schedule_info/`, config);
    }

    getCalendarEmployeeChoices(params) {
        return this.apiGet(`calendar_employee_choices/`, {params});
    }

    areShiftsEnabled(params) {
        return this.apiGet(`are_shifts_enabled/`, {params});
    }

    yearMonthChoices(employeeId) {
        return this.apiGet('year_month_choices/', {params: {employee: employeeId}});
    }

    dailyShifts(params) {
        return this.apiGet('daily_shifts/', {params});
    }
}
