<script setup>
    import {ONBOARDING_TASK_CODENAME, ONBOARDING_TASK_STATUS} from '@jetCommon/constants/onboarding.js';
    import {useOnboarding} from '@/features/onboarding/composables/onboarding.js';

    import CompanyHeadquartersPoliciesForm from '@jetCommon/components/onboarding/CompanyHeadquartersPoliciesForm.vue';
    import OnboardingTaskItem from '@/features/onboarding/components/tasks/base/OnboardingTaskItem.vue';

    const {fetchAll, taskByCodename, companyId} = useOnboarding();
    const task = computed(() => taskByCodename.value[ONBOARDING_TASK_CODENAME.COMPANY_HEADQUARTERS_POLICIES]);
    const taskIsToDo = computed(() => task.value.status === ONBOARDING_TASK_STATUS.TODO);

    const formRef = ref(null);

    const actions = computed(() => {
        return [
            {
                main: true,
                text: taskIsToDo.value ? 'Compila' : 'Visualizza',
                dialog: {
                    title: 'Sedi aziendali',
                    name: 'main',
                    confirm_button_text: 'Salva',
                    confirm_callback() {
                        formRef.value.submit();
                    },
                    show_confirm_button: taskIsToDo.value,
                },
            },
        ];
    });
</script>

<template>
    <OnboardingTaskItem
        :actions="actions"
        description="Comunicaci la sede legale della tua azienda e le eventuali altre sedi operative."
        :codename="ONBOARDING_TASK_CODENAME.COMPANY_HEADQUARTERS_POLICIES"
        title="Sedi aziendali">
        <template #dialogMainBody>
            <CompanyHeadquartersPoliciesForm
                ref="formRef"
                :read-only="!taskIsToDo"
                :task="task"
                :company-id="companyId"
                @submitted="fetchAll()" />
        </template>
    </OnboardingTaskItem>
</template>
