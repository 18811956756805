<script setup>
    import {
        PAY_MONTH_KIND_LABEL,
        ABSENCES_LABEL_KIND,
        ABSENCES,
        PAY_MONTH_KIND,
    } from '@jetCommon/constants/company-settings-constants';

    import JetCallout from '@jetDS/components/JetCallout.vue';
    import JetForm from '@jetDS/components/JetForm.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import JetIcon from '@jetDS/components/JetIcon.vue';
    import JetSelect from '@jetDS/components/JetSelect.vue';
    import JetTooltip from '@jetDS/components/JetTooltip.vue';

    const props = defineProps({
        readOnly: {
            type: Boolean,
            default: false,
        },
        task: {
            type: Object,
            required: true,
        },
    });

    const emit = defineEmits(['submitted']);

    const {$api, $jetMessage} = inject('jet');
    const formRef = ref(null);

    const formData = reactive({
        pay_day: props.task.submitted_data?.pay_day || null,
        pay_month: props.task.submitted_data?.pay_month || null,
        absences: props.task.submitted_data?.absences,
    });
    const requiredFieldRule = {required: true, message: 'Campo obbligatorio', trigger: 'blur'};
    const formRules = computed(() => ({
        pay_time: [
            {
                required: true,
                message: 'Campi obbligatori',
                validator: (rule, value, callback) => {
                    if (!formData.pay_day || !formData.pay_month) {
                        callback(new Error('Both pay_day and pay_month are required'));
                    } else {
                        callback();
                    }
                },
                trigger: 'blur',
            },
        ],
        absences: [requiredFieldRule],
    }));

    const payMonthChoices = ref([]);
    $api.companySettings
        .getPayMonthChoices()
        .then(response => {
            const choices = response.data;
            payMonthChoices.value = choices.map(choice => {
                return {
                    value: choice.value,
                    display_name: `del ${PAY_MONTH_KIND_LABEL[choice.value]}`,
                };
            });
        })
        .catch($api.end);

    async function fetchPayDayChoices() {
        return (await $api.companySettings.getPayDayChoices().catch($api.end))?.data ?? [];
    }

    const FORM_STATUS = {
        ERROR: 'ERROR',
        WARNING: 'WARNING',
        READY: 'READY',
        IN_PROGRESS: 'IN_PROGRESS',
    };

    const absencesExpensesChoices = ref([]);
    $api.companySettings
        .getAbsencesExpensesChoices()
        .then(response => {
            const choices = response.data;
            absencesExpensesChoices.value = choices.map(choice => {
                return {
                    display_name: ABSENCES_LABEL_KIND[choice.value],
                    value: choice.value,
                };
            });
        })
        .catch($api.end);

    const status = computed(() => {
        if (formData.absences === ABSENCES.STANDARD && formData.pay_month === PAY_MONTH_KIND.CURRENT)
            return FORM_STATUS.ERROR;
        else if (
            formData.absences === ABSENCES.STANDARD &&
            formData.pay_month === PAY_MONTH_KIND.NEXT &&
            formData.pay_day === 1
        )
            return FORM_STATUS.ERROR;
        else if (formData.absences === ABSENCES.STANDARD && formData.pay_day < 6) return FORM_STATUS.WARNING;
        else if (formData.absences && formData.pay_day && formData.pay_month) return FORM_STATUS.READY;
        else return FORM_STATUS.IN_PROGRESS;
    });

    const errorType = computed(() => {
        if (formData.absences === ABSENCES.STANDARD && formData.pay_month === PAY_MONTH_KIND.CURRENT) return 'general';
        if (
            formData.absences === ABSENCES.STANDARD &&
            formData.pay_month === PAY_MONTH_KIND.NEXT &&
            formData.pay_day === 1
        )
            return 'specific';
        return null;
    });

    async function submit() {
        if (props.readOnly) {
            $jetMessage.warning('Non puoi modificare i dati in questo momento.');
            return;
        }

        const formEl = formRef.value.getElFormRef();
        const valid = await formEl.validate().catch(() => false);

        if (status.value === FORM_STATUS.ERROR) return;

        if (valid) {
            $api.companyOnboardingTasks
                .submitData(props.task.id, {
                    ...formData,
                })
                .then(() => emit('submitted'))
                .catch($api.end);
        }
    }

    defineExpose({
        formData,
        submit,
    });
</script>

<template>
    <JetForm ref="formRef" :rules="formRules" :model="formData">
        <JetCallout v-if="errorType && errorType === 'general'" class="mt-4 mb-8" type="error" plain>
            Con Presenze e assenze Standard non è possibile ricevere cedolini e documenti nel mese corrente.
        </JetCallout>

        <JetCallout v-if="errorType && errorType === 'specific'" class="mt-4 mb-8" type="error" plain>
            Con Presenze e assenze Standard non è possibile programmare l'elaborazione nel giorno 1 del mese successivo.
        </JetCallout>

        <JetCallout v-if="status === FORM_STATUS.WARNING && !readOnly" class="mt-4 mb-8" type="warning" plain>
            Con Presenze e assenze Standard, impostare il Giorno e mese entro cui ricevere cedolini e documenti troppo
            presto, nel mese successivo, potrebbe non garantire il tempo necessario per l'elaborazione corretta,
            soprattutto se nel periodo cadono delle festività che riducono il numero di giorni disponibili. Ti
            consigliamo di impostarlo dal 6 del mese successivo in poi.
        </JetCallout>

        <JetFormItem prop="absences">
            <template #label>
                <JetTooltip placement="bottom">
                    <div class="flex justify-between items-center">
                        <span>Presenze e assenze</span>
                        <JetIcon name="info-circle" icon-style="regular" />
                    </div>
                    <template #content>
                        Presenze e assenze Standard è la gestione utilizzata solitamente dalle aziende che pagano gli
                        stipendi a inizio mese successivo e prevede l’utilizzo delle variabili di presenza del mese di
                        competenza (es. cedolino di Marzo con presenze/assenze relative a Marzo). Presenze e assenze
                        Differite invece è la gestione utilizzata solitamente dalle aziende che pagano gli stipendi
                        entro la fine del mese e prevede l’utilizzo delle variabili di presenza del mese precedente (es.
                        cedolino di Marzo con presenze/assenze relative a Febbraio).
                    </template>
                </JetTooltip>
            </template>

            <JetSelect
                v-if="absencesExpensesChoices.length > 0"
                v-model="formData.absences"
                :disabled="readOnly"
                filterable
                full-width
                fit-input-width
                placeholder="Seleziona"
                option-height-fluid
                :options="absencesExpensesChoices">
                <template #optionCaption="{option}">
                    {{ option.caption }}
                </template>
            </JetSelect>
        </JetFormItem>

        <JetFormItem prop="pay_time">
            <template #label>
                <JetTooltip placement="bottom">
                    <template #content>
                        Il giorno è solo indicativo. Se un mese ha meno giorni rispetto al valore selezionato, questo
                        valore verrà aggiornato all'ultimo giorno disponibile del mese, inoltre se durante
                        l’elaborazione del singolo ciclo paga ci sono delle festività, la data verrà posticipata al
                        primo giorno lavorativo successivo garantendo così il tempo necessario per l'elaborazione.
                    </template>
                    <div class="flex justify-between items-center">
                        <span>Giorno e mese entro cui ricevere cedolini e documenti</span>
                        <JetIcon name="info-circle" icon-style="regular" />
                    </div>
                </JetTooltip>
            </template>

            <div class="PayrollDatesForm_Row">
                <div class="PayrollDatesForm_PayDayCol">
                    <JetSelect
                        v-model="formData.pay_day"
                        :disabled="readOnly"
                        :options="fetchPayDayChoices"
                        filterable
                        full-width
                        placeholder="Giorno" />
                </div>
                <div class="PayrollDatesForm_PayMonthCol">
                    <JetSelect
                        v-model="formData.pay_month"
                        :disabled="readOnly"
                        :options="payMonthChoices"
                        filterable
                        full-width
                        placeholder="Mese" />
                </div>
            </div>
        </JetFormItem>

        <JetCallout class="mt-4" type="warning" plain>
            Una volta salvati questi campi non sarà più possibile modificarli. Se hai fatto un errore contatta
            l'assistenza.
        </JetCallout>
    </JetForm>
</template>

<style lang="scss" scoped>
    .PayrollDatesForm {
        &_Row {
            width: 100%;
            display: flex;
            gap: 16px;
        }

        &_PayDayCol {
            flex: 1 1 35%;
        }

        &_PayMonthCol {
            flex: 1 1 65%;
        }
    }
</style>
