import CompanyPolicyApi from '@jetCommon/api/company-policy.js';

export default class CompanyHeadquartersPolicyApi extends CompanyPolicyApi {
    static resourceName = 'company_headquarters_policies';

    getCompanyHeadquartersPolicyChoices(params = {}) {
        return this.apiGet(`headquarters_choices/`, {params});
    }

    assignCompanyHeadquartersPoliciesBatch(data) {
        return this.apiPost(`assign_company_headquarters_policies_batch/`, data);
    }
}
