// eslint-disable-next-line no-magic-numbers
function formatNumberToIta(number, toFixed = 2) {
    const formattedValue = Number(number).toLocaleString('it-IT', {
        minimumFractionDigits: toFixed,
        maximumFractionDigits: toFixed,
    });
    // Prevent returning '-0,00' when the number is negative and close to zero
    const formattedZero = `0,${'0'.repeat(toFixed)}`;
    return formattedValue === `-${formattedZero}` ? formattedZero : formattedValue;
}

function formatNumberWithOptionalDecimals(value) {
    // Example: 2.0 -> 2
    // Example: 3.1415 -> 3,14
    const numberValue = Number(value || 0);
    const toFixed = numberValue % 1 === 0 ? 0 : 2;
    return formatNumberToIta(numberValue, toFixed);
}

function itaPercentageToNumber(percentage) {
    return Number(percentage.replace(',', '.'));
}

function formatPercentage(number, toFixed = 2) {
    return `${formatNumberToIta(number, toFixed).replace(',00', '')}%`;
}

function formatPercentageWithSign(number, toFixed = 2) {
    return `${number > 0 ? '+' : ''}${formatPercentage(number, toFixed)}`;
}

function roundTo(num, precision) {
    const factor = Math.pow(10, precision);
    return Math.round(num * factor) / factor;
}

function isNumber(value) {
    return typeof value === 'number';
}

export {
    formatNumberToIta,
    formatNumberWithOptionalDecimals,
    formatPercentage,
    formatPercentageWithSign,
    itaPercentageToNumber,
    roundTo,
    isNumber,
};
